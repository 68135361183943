.loginpageform{
    margin-top: 0em;
}

.loginpagefieldset{
    padding: 0;
    margin: 0;
    border: none;
  }
.loginpageloginmargin {
    margin-top: 0em;
}

.loginpageinputlogin {
    width: 80%;
}

.loginpageloginbox{
    height: calc(100vh - 20em);
  }
  .loginpageloginmargin{  
    margin-top: 2em;
  }
  
  .loginpagesubmitmargin1{
    margin-top: 2em;
  }
  
  .loginpagefieldset{
    padding: 0;
    margin: 0;
    border: none;
  }
  
  .loginpageloginhr{
    width: 80%;
    margin-left: 10%;
  }
  

