.selectlanguages-input{
    margin-left: 0.5em;
    color:yellow;
    font-weight: bold;
}

.selectlanguages-label{
    margin-left: 0.5em;
    color:yellow;
    
}

.radio{
    display:inline;
}

.languages{
    display:inline-block;
    margin: 0;
    padding: 10px;
    height:3em;
    
}

.selectlanguages-input:checked+label>div {
    background-color: gray;
    border-radius: 1em;
    font-weight: bold;
}