.loginpagecontactinfofooter{
    height: 4em;
    border: none;
    outline: none;
  }
  .loginpagecontactmargin{
    /* margin-top: 9em; */
  }
  .loginpagecontactmargin1{
    margin-top: 1em;
  }
  .loginpagecontacttext{
    /*font-size:100%;*/
  }
  .loginpagecontacttext1{
    /*font-size:70%;*/
  }