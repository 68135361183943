.loginpagemaininfo{
    height: calc(100vh - 3em)
  }
  .loginpageinfotext{
    /* margin-top: 12em; */
    filter: none;  
  }
  
  .loginpageinfotextheight{
    text-align:center;
    vertical-align: bottom;
    align-items: flex-end;
    display: inline-flex;
    height: 50%;
  }
  .loginpageinfotextheight1{
    text-align:center;
    margin: 0;
    height: 50%;
  }
  .loginpageinfotext1{
    padding: 1em;
  }
