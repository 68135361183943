.loginpagelogobox{
    height: 12em;
    margin: 0;
    padding: 0;
    border: none;  
  }
  
  .loginpagelogo{  
    margin-top: 3em;
  }
  .loginpageservername{  
    margin-top: 1em;
    text-transform: uppercase;
  }