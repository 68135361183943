
.basepagework {
  margin: 0;
  padding: 0;
  border: none; 
  display: flex;
  height: calc(100vh - 3em - 4em);
  text-align: center;
}
.basepagecol,
[class*='col-'] {
  flex-grow: 1;  
}
.basepagecol-6 {
  flex: 0 0 350px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}
.basepagecol-1 {
  flex: 0 0 100px;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  background-color: darkblue;
}
.basepagecol-10 {
  flex: 0 0 100px;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  background-color: lightblue;
}


.basepagefooter {
  background-color:lightgray;
  color: white;
  height: 3em;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 3em;
  max-height: 3em; 
  outline: 0px solid;
  align-items: flex-end;
  vertical-align: bottom;
  text-align: center;
  background-image: url('../../img/brick-wall.svg');
}


.basepagewindow {
  color:black;
  border: 1px solid brown;
  font-weight: bold;
  overflow:-moz-hidden-unscrollable;
  height: calc(100vh - 3em - 4em);
  background-color: lightgoldenrodyellow;
}

.basepagetable {
  color:black;
  border: 1px solid brown;
  font-weight: bold;
}

.basepagetable caption{
  caption-side: top;
  color:brown;
  font-weight: bold;
}

.basepagetable thead{
  color:black;
  border: 1px solid brown;
  font-weight: bold;
}

.basepagetable th{
  color:black;
  border: 1px solid brown;
  font-weight: bold;
}

.basepagetable tbody{
  border: 1px solid brown;
}

.basepagetable td{
  border: 1px solid brown;
}

.my-table-tree1 {
  list-style: url("../../img/folder.svg") inside;
  vertical-align: middle;
  text-align: left;
  font-weight: bold;
  scale: 50%;
}
.my-table-tree2 {
  list-style: url("../../img/files.svg") inside;
  vertical-align: middle;
  text-align: left;
  scale: 50%;
}

.my-table-first{
  color:black;
  font-weight: bold;
}

.my-table-second{
  color:blue;
  font-weight: normal;
}

.my-table-1 {
  overflow: auto;
  background-color: lightgoldenrodyellow;
  padding: 15px;
  height: calc(100vh - 3em - 3em);
}

.my-table-3 {
  overflow: auto;
  background-color: lightgoldenrodyellow;
  padding: 15px;
  height: calc(50vh);
}

.my-table-2 {
  overflow: auto;
  background-color: lightpink;
  padding: 0px;
  height: 0px;
}

.my-table-4 {
  overflow: auto;
  background-color: lightpink;
  padding: 0px;
  height: calc(50vh - 3em - 3em);
}
