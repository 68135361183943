.my-menu-navbar {
    background-color:darkcyan;
    color: black;
    height: 3em;
    margin: 0;
    padding: 0;
    border: none; 
    min-height: 3em;
    max-height: 3em;
  }
  
  .my-search-button {
    color: rgb(56, 56, 47);
    font-weight:bold;
  }

.active {
  color: black;
}
/*
.nav-link {
  color: darkgray;
}

.nav-link:hover {
  color: black;
}
  */