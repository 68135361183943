
.emoji{
    height: 1em;
    width: 1em;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 12px;    
}

table th, table td {
    text-align: left;
    padding: 16px;
    border: 1px solid #ddd;
    height: 1em;
    line-height:0.8em;
}
 
table tr:nth-child(even) {
    background-color: #f2f2f2;
    height: 1em;
    line-height:0.8em;
}

table tr:hover {
    background-color: #ddd;
    height: 1em;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
    height: 1em;
}

tfoot td  {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
}


.pagination {
    margin-top: 10px;
    padding: 0.5em;
  }

  .myTable {
    overflow: visible;
  }