/* Loginpage */
.loginpageregisterrow {
  padding: 0;
  margin: 0;
  display: flex;
  height: 100vh;
  text-align: center;
}
.loginpagecol,
[class*='col-'] {
  flex-grow: 1;  
}
.loginpagecol-6 {
  flex: 0 0 350px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}
