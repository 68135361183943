
.App-loader {
    height: 10vmin;
    pointer-events: none;
    position: absolute;
    display: none;
    top: calc(50% - 5vh);
    left: calc(50% - 5vh);
  }

  
@media (prefers-reduced-motion: no-preference) {
    .App-loader {
      animation: App-loader-spin infinite 20s linear;
    }
  }

  
@keyframes App-loader-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  